import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import VueProgressBar from "vue-progressbar";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/registerServiceWorker";
import i18n from "@/i18n";
import VueMeta from "vue-meta";
import VueMoment from "vue-moment";
import moment from "moment";
import "moment/locale/ne";
import "@/vee-validate";

Vue.use(VueProgressBar, {
  color: "#67c23a",
  failedColor: "red",
  height: "2px",
  thickness: "3px",
  autoRevert: true,
  autoFinish: false
});

Vue.use(VueMoment, { moment });
Vue.use(ElementUI, { locale });

Vue.use(VueMeta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true
});

(Vue.config.productionTip = false),
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount("#app");
